/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-up-right-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 8a8 8 0 1016 0A8 8 0 000 8m5.904 2.803a.5.5 0 11-.707-.707L9.293 6H6.525a.5.5 0 110-1H10.5a.5.5 0 01.5.5v3.975a.5.5 0 01-1 0V6.707z"/>',
    },
});
